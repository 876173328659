<!--  -->
<template>
  <div class="form-wrapper">
    <van-form ref="form">
      <van-field
        readonly
        disabled
        v-model="form.applyName"
        name="applyName"
        label="用户名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypeOptions"
        :fieldProps="{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型'
        }"
      ></van-field-select>
      <van-field
        readonly
        disabled
        v-model="form.idCardNum"
        name="idCardNum"
        label="证件号码"
        placeholder="请输入证件号码"
        required
      />
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
      />
      <van-field
        v-model="form.email"
        name="email"
        label="电子邮箱"
        placeholder="请输入电子邮箱"
      />
      <van-field-area
        v-model="form.area"
        name="area"
        :fieldProps="{
          required: true,
          label: '应聘前所在城市',
          placeholder: '应聘前所在城市'
        }"
      />
      <van-field
        v-model="form.bankId"
        name="bankId"
        label="社会保障卡开户银行"
        placeholder="请输入社会保障卡开户银行"
        required
      />
      <van-field
        v-model="form.bankAccount"
        name="bankAccount"
        label="社会保障卡金融账号"
        placeholder="请输入社会保障卡金融账号"
        required
      />
      <van-field
        v-model="form.applyCmpy"
        name="applyCmpy"
        label="应聘单位"
        placeholder="请输入应聘单位"
        required
      />
      <van-field-calendar
        v-model="form.entryDate"
        name="entryDate"
        label="入职日期"
        placeholder="请输入"
        :fieldProps="{
          required: true,
          label: '入职日期',
          placeholder: '请输入入职日期'
        }"
      />

      <div class="tip">
        <van-notice-bar
          color="#1989fa"
          background="#ecf9ff"
          text="最高学历信息"
        />
      </div>

      <van-field
        v-model="form.college"
        name="college"
        label="毕业院校"
        placeholder="请输入毕业院校"
        required
      />
      <van-field
        v-model="form.major"
        name="major"
        label="所学专业"
        placeholder="请输入毕业院校"
      />
      <van-field-select
        v-model="form.education"
        :options="educationOptions"
        :fieldProps="{
          label: '学历',
          placeholder: '请选择学历',
          required: true
        }"
      ></van-field-select>
      <van-field-select
        v-model="form.educationType"
        :options="educationTypeOptions"
        :fieldProps="{
          label: '学历形式',
          placeholder: '请选择学历形式',
          required: true
        }"
      ></van-field-select>
      <van-field-calendar
        v-model="form.graduateDate"
        name="graduateDate"
        label="毕业时间"
        placeholder="请输入"
        :fieldProps="{
          required: true,
          label: '毕业时间',
          placeholder: '请输入毕业时间'
        }"
      />
    </van-form>
  </div>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import rules from "./rules";
export default {
  data() {
    return {
      form: {
        applyName: "",
        idCardType: "居民身份证",
        phone: "",
        idCardNum: "",
        applyDate: "",
        applyAmtYuan: "",
        college: "",
        major: "",
        education: "",
        educationType: "",
        degree: "",
        graduateDate: "",
        bankAccount: "",
        bankId: "",
        email: "",
        area: [],
        applyCmpy: ""
      },
      rules: rules,
      educationOptions: [
        {
          label: "本科",
          value: "本科"
        },
        {
          label: "硕士",
          value: "硕士"
        },
        {
          label: "博士",
          value: "硕士"
        }
      ],
      educationTypeOptions: [
        {
          label: "全日制",
          value: "全日制"
        },
        {
          label: "非全日制",
          value: "非全日制"
        }
      ]
    };
  },
  mixins: [formMixin],
  props: {
    detail: Object
  },
  watch: {
    ["form.education"]() {
      const value = this.form.education;
      this.form.educationType = "";
      if (value === "本科") {
        this.educationTypeOptions = [{ label: "全日制", value: "全日制" }];
      } else {
        this.educationTypeOptions = [
          { label: "全日制", value: "全日制" },
          { label: "非全日制", value: "非全日制" }
        ];
      }
    }
  },
  mounted() {},
  methods: {
    validate() {
      const validator = new Schema(this.rules);
      return new Promise((resolve, reject) => {
        validator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(() => {
            resolve({
              ...this.form,
              proCode: this.form.area[0],
              cityCode: this.form.area[1],
              countyCode: this.form.area[2]
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
}
.tip {
  margin: 0.2rem;
}
</style>
