export default {
  phone: [
    {
      message: "请输入手机号",
      required: true
    },
    {
      message: "手机号格式不正确",
      pattern: /^(?:(?:\+|00)86)?1\d{10}$/
    }
  ],
  email: [
    {
      message: "请输入正确的电子邮箱",
      pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    }
  ],
  area: [
    {
      message: "请选择应聘前所在城市",
      required: true
    }
  ],
  bankAccount: [
    {
      message: "请输入社会保障卡金融账号",
      required: true
    },
    {
      message: "社会保障卡金融账号最多20字",
      max: 20
    }
  ],
  bankId: [
    {
      message: "请输入社会保障卡开户银行",
      required: true
    },
    {
      message: "社会保障卡开户银行最多100字",
      max: 100
    }
  ],
  applyCmpy: [
    {
      message: "请输入应聘单位",
      required: true
    },
    {
      message: "应聘单位最多100字",
      max: 100
    }
  ],
  entryDate: [
    {
      message: "请输入入职时间时间",
      required: true
    }
  ],
  college: [
    {
      message: "请输入毕业院校",
      required: true
    },
    {
      message: "毕业院校最多100字",
      max: 100
    }
  ],
  major: [
    {
      message: "所学专业最多100字",
      max: 100
    }
  ],
  education: [
    {
      message: "请输入学历",
      required: true
    }
  ],
  educationType: [
    {
      message: "请选择学历形式",
      required: true
    }
  ],
  graduateDate: [
    {
      message: "请输入毕业时间",
      required: true
    }
  ]
};
